.location-maps {

	width: 100%;
	display: flex;
	flex-wrap: wrap;

	.location-sidebar {

		width: 100%;
		overflow: hidden;
		background: $brand;
		text-align: center;

		@include media-breakpoint-up(xl) {
			max-width: 480px;
			padding: 0 0 0 30px;
			max-width: 480px;
			max-height: 770px;
		}

		.sidebar-wrap {

			width: 100%;
			height: 100%;

			@include media-breakpoint-up(xl) {
			    transform: translateX(30px);
			}

		}

		ul {

			display: none;

			margin-bottom: 0;
			padding-bottom: 60px;

			@include media-breakpoint-up(xl) {
				display: block!important;
				margin-top: 95px;
				height: 90%;
				overflow: scroll;
				padding-right: 60px;
			}

			li {

				&:not(:last-child) {

					margin-bottom: 20px;

					@include media-breakpoint-up(xl) {

						margin-bottom: 40px;

					}

				}

			}


		}

		a {

			display: block;
			color: $white;
			text-transform: uppercase;
			font-size: 18px;
			letter-spacing: 0.1em;
			opacity: 0.5;
			transition: opacity 0.3s ease;

			@include media-breakpoint-up(xl) {
				font-size: 22px;
			}

			&:hover {
				opacity: 1;
			}

			&.active {
				opacity: 1;
			}

			&.map-link-mobile {

				padding-top: 30px;
				padding-bottom: 30px;
				@extend .d-xl-none;

			}

		}

	}

	.location-map {

		position: relative;
		width: 100%;
		height: auto;
		display: none;
		opacity: 0;

		@include media-breakpoint-up(md) {

			min-height: 600px;

		}

		@include media-breakpoint-up(xl) {

			width: calc(100% - 480px);
			height: auto;

		}

		&.active {

			display: block;

			transition: opacity 0.5s ease-in-out 1s;
			opacity: 1;

		}

		.map-container {
			width: 100%;
			height: 100%;
		}

		.map-iframe {

			display: none;

			@include media-breakpoint-up(md) {

				width: 100%;
				height: 100%;
				position: relative;
				display: block;

				iframe {

					width: 100%;
					height: 100%;

				}

			}

		}

		.map-popup {

			background: $white;
			padding: 40px 35px;
			border: 1px solid $border-light;
			text-align: center;
			max-width:100%;
			visibility: hidden;
			opacity: 0;
			transition: all 0.3s ease-in-out;

			@include media-breakpoint-up(md) {

				max-width: 390px;
				position: absolute;
				top: 95px;
				left: 10px;
				z-index: 100;
				transform: translateX(50px);

			}

			@include media-breakpoint-up(xl) {

				top: 95px;
				left: 95px;

			}

			&.map-drag {
				opacity:0!important;
				pointer-events:none;
			}

			&.active {

				visibility: visible;
				opacity: 1;
				transform: translateX(0);
				transition: all 0.6s ease-in-out;

			}

			.location {
				font-size: 18px;
				letter-spacing: 0.2em;
				text-transform: uppercase;
				color: $brand;

				@include media-breakpoint-up(lg) {
					font-size: 22px;
				}

				&:after {
					content: '\e90c';
					@include icons;
				    display: block;
				    font-size: 40px;
				    margin-top: 20px;
				    margin-bottom: 20px;

				}
			}

			address {
				font-size: 15px;
				//color: $grey-alt;
				text-transform: uppercase;
				letter-spacing: 0.2em;
				line-height: 30px;
				margin-bottom: 45px;

				@include media-breakpoint-up(lg) {
					font-size: 17px;
				}
			}

			a {
				color: $brand;
				font-size: 18px;
				display: block;
				letter-spacing: 0.05em;

				@include media-breakpoint-up(lg) {
					font-size: 22px;
				}
				span {
					margin-right: 10px;
					position: relative;
					top: 2px;
				}
			}
			.tel {
				margin-bottom: 22px;
			}

		}

	}

}

.acf-map {
	width: 100%;
	height: 100%;

	button {
		transform: rotate(0);
		&:before {
			display: none;
		}
		&:hover {
			background: none;
		}
	}
}

.text-banner {

	text-align: center;

	&.has-bg {

		background: $light-grey;

	}

	.text-banner-inner {

		max-width: 820px;
		margin: 0 auto;
		padding: 40px 0;

		@include media-breakpoint-up(lg) {

			padding: 80px 0;

		}

	}

	.title {

		font-size: 28px;
		line-height: 40px;
		letter-spacing: 0.2em;
		text-transform: uppercase;
		margin-bottom: 30px;

		@include media-breakpoint-up(lg) {

			font-size: 45px;
			line-height: 60px;
			margin-bottom: 40px;

		}

	}
}
