.portrait-thumbs {
	position: relative;
    padding-top: 60px;
    padding-bottom: 80px;
    padding-left: 0;

	@include media-breakpoint-up(sm) {
		padding-top: 95px;
		padding-bottom: 145px;
	}

	@include media-breakpoint-up(md) {
	}

	@include media-breakpoint-up(lg) {
		padding-bottom: 195px;
	}

	@include media-breakpoint-up(xl) {
		padding-left: 150px;
	}

	&:before {
		content:'';
		position: absolute;
		top:0;
		height:100%;
		transform: skew(-25deg);
    	width: 100%;
    	left: -50%;
    	background-color:#d61e3a;

		@include media-breakpoint-up(md) {
			background-color:$brand;
			width:50%;
			left:-30%;
		}

		@include media-breakpoint-up(lg) {
			left:-25%;
		}
	}

	.portrait-thumbs-header {
		text-align: center;
		position: relative;
		padding-bottom: 50px;

		@include media-breakpoint-up(sm) {
			padding-bottom: 75px;
		}

		@include media-breakpoint-up(md) {
			padding-left: 75px;
			text-align: left;
		}

		@include media-breakpoint-up(xl) {
			padding-left: 0;
		}

		&__title {
    		display: inline-block;
    		color: white;
    		text-transform: uppercase;
    		margin-bottom: 0;

    		&:before {
    			color:white;
    		}
		}
	}

	.inner-carousel {
		.portrait-card {
			position: relative;
			background-color:black;
			overflow:hidden;
			display:flex!important;
			justify-content: middle;
			align-items:center;
			height:475px;

			@include media-breakpoint-up(lg) {
				height:575px;
			}

			@include media-breakpoint-up(xl) {
				width:105%!important;
			}

			.background-image {
				position:absolute;
				top:0;
				right:0;
				bottom:0;
				left:0;
				//@include background-cover();
				opacity:0.5;
				transition: 1.25s;
			}

			.portrait-card-textarea {
				position: relative;
				text-align: center;
    			margin-left: auto;
    			margin-right: auto;
    			max-width: 85%;

    			@include media-breakpoint-up(xxl) {
					max-width: 75%;
    			}

    			&:before,
    			&:after {
    				content:'';
    				height:50px;
    				width:2px;
    				background-color:$light;
    				left:50%;
    			}

    			&:before {
    				position:absolute;
    				bottom:100%;
    				transform:translateY(-75px);
    				transition: 0.5s;
    				opacity:0;
    			}

    			&:after {
    				position:absolute;
    				top:100%;
    				transform:translateY(75px);
    				transition: 0.5s;
    				opacity:0;
    			}

				&__hdr {
					font-size:20px;
					color:white;
					text-transform: uppercase;
					letter-spacing: 2px;
					margin-bottom: 15px;
					transform: translateY(50px);
					transition: 0.5s;
				}
				&__para {
					color:white;
					max-width:275px;
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 0;
					opacity:0;
					transition: 0.5s;
					font-size:15px;
					height:95px;
					line-height: 1.5;

					@include media-breakpoint-up(lg) {
						height:85px;
						font-size:17px;
					}

					@include media-breakpoint-up(xxl) {
						line-height: 1.8;
					}
				}
			}

			&:hover {
				.background-image {
					opacity:0.25;
					transform:scale(1.25);
				}

				.portrait-card-textarea {
					&__hdr {
						transform: translateY(0px);
					}

					&__para {
						opacity:1;
						transition-delay: 0.25s;
					}

					&:before {
						opacity:1;
						transition-delay: 0.25s;
						transform:translateY(-15px);

						@include media-breakpoint-up(lg) {
							transform:translateY(-50px);
						}
					}

					&:after {
						opacity:1;
						transition-delay: 0.25s;
						transform:translateY(15px);

						@include media-breakpoint-up(lg) {
							transform:translateY(50px);
						}
					}
				}

	            .learn-more {
	                .learn-more-text {
	                    transform:translateX(0);
	                }
	            }
			}
		}


		.slick-active {
			@include media-breakpoint-down(md) {
				.portrait-card {
					.background-image {
						opacity:0.25;
						transform:scale(1.25);
					}

					.portrait-card-textarea {
						&__hdr {
							transform: translateY(0px);
						}

						&__para {
							opacity:1;
							transition-delay: 0.25s;
						}

						&:before {
							opacity:1;
							transition-delay: 0.25s;
							transform:translateY(-15px);

							@include media-breakpoint-up(lg) {
								transform:translateY(-25px);
							}
						}

						&:after {
							opacity:1;
							transition-delay: 0.25s;
							transform:translateY(15px);

							@include media-breakpoint-up(lg) {
								transform:translateY(25px);
							}
						}
					}

		            .learn-more {
		                .learn-more-text {
		                    transform:translateX(0);
		                }
		            }
				}
			}
		}
	}

	.slick-slide {
		margin: 0 10px;

		@include media-breakpoint-up(xl) {
			margin: 0 25px;
		}

	}

	.slick-list {
		margin: 0;

		@include media-breakpoint-up(xl) {
			margin: 0 -25px;
		}
	}

	.slick-track {

		@include media-breakpoint-up(xl) {
			margin-left: -5px;
		}
	}
}

