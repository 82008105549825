/* ==========================================================================

   POST

========================================================================== */

/* POST BOX
   ========================================================================== */
.cat-year {
  position: relative;
  margin-bottom: 20px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 40px;
  }

  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background: darken($white-alt, 5%);

    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .title {
    color: $brand;
    font-family: $font-secondary;
    font-size: 30px;
    line-height: 40px;
    display: inline-block;

    position: relative;
    z-index: 1;
    background: $white-alt;
    padding-right: 30px;

    @include media-breakpoint-up(lg) {
      font-size: 35px;
      padding-right: 100px;
    }
  }
}

.post-box-small {
  margin-bottom: 30px;
  position: relative;
  max-height: 740px;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    margin-bottom: 60px;
  }

  &.post-box-event {
    display: flex;
    position: relative;
    flex-direction: column;

    .title {
      @include media-breakpoint-up(xl) {
        min-height: auto !important; //overriding legacy styling
      }
    }

    .post-box-content {
      display: flex;
      flex-grow: 1;
      padding: 20px;

      @include media-breakpoint-up(md) {
        padding: 30px;
      }

      @include media-breakpoint-up(lg) {
        min-height: auto !important; //overriding legacy styling
      }

      &:after {
        display: none !important; //overriding legacy styling
      }

      .title.event {
        color: #838383;
        font-size: 23px;
        margin-bottom: 5px;

        @include media-breakpoint-up(md) {
          font-size: 35px;
          margin-bottom: 15px;
        }
      }

      .event-details {
        margin-bottom: 5px;

        & p {
          color: #005eb8;
          text-transform: uppercase;
          font-size: 12px;
          letter-spacing: 2.8px;
          margin-bottom: 2px;

          @include media-breakpoint-up(md) {
            font-size: 14px;
          }
        }

        @include media-breakpoint-up(md) {
          margin-bottom: 10px;
        }

        &-wrapper {
          display: flex;
          flex-wrap: wrap;
          border-bottom: 1px solid rgba(112, 112, 112, 0.1);
          margin-bottom: 20px;
        }

        .event-detail {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          margin-right: 20px;

          &:last-of-type {
            margin-right: 0;
          }

          @include media-breakpoint-up(md) {
            margin-bottom: 20px;
            margin-right: 40px;
          }

          p {
            color: $brand;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.33;
            letter-spacing: 1.2px;
            text-align: left;
            margin-bottom: 0;
          }

          img {
            width: 15px;
            margin: 0 10px 0 0;
          }
        }
      }

      &.alt {
        border: 2px solid #005eb8;
        border-top: 0;
      }

      & .description {
        color: #838383;
        font-size: 13px;
        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
          font-size: 16px;
          margin-bottom: 30px;
        }
      }

      .read-more {
        text-transform: uppercase;
        color: #005eb8;
        margin-left: auto;
        margin-top: auto;
        font-size: 13px;
        letter-spacing: 1.6px;

        @include media-breakpoint-up(md) {
          font-size: 16px;
        }
      }
    }

    .cat {
      position: absolute;
      top: 25px;
      right: 15px;
      color: #212121;
      background: #fff;
      text-align: center;
      padding: 20px 30px 20px 40px;
      font-size: 19px;
      display: inline-block;
      clip-path: polygon(20% 0, 100% 0, 100% 100%, 0% 100%);
      border: none;

      &.alt {
        background: #005eb8;
        color: #fff;
      }
    }
  }

  &.last {
    max-height: 696px;
  }

  &.post-box-news {
    .post-box-content {
      height: 100%;

      @include media-breakpoint-up(lg) {
        min-height: 340px;
      }
    }

    p {
      margin-bottom: 15px;
    }

    .title {
      @include media-breakpoint-up(xl) {
        min-height: 84px;
      }
    }

    .view {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .post-box-image {
    display: block;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    width: 100%;
    min-height: 250px;

    @include media-breakpoint-up(xl) {
      min-height: 280px;
    }

    @include media-breakpoint-up(xxl) {
      min-height: 300px;
    }
  }

  .post-box-content {
    position: relative;
    background: $white;
    height: 100%;
    padding: 30px;

    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      padding: 40px 40px 30px 40px;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;

      width: 192px;
      height: 55px;
      background: $white-alt;

      opacity: 0;
      transition: all 0.3s ease-in-out 0.1s;
    }

    @include media-breakpoint-up(xxl) {
      padding: 50px 55px 35px 55px;
    }
  }

  .title,
  .title a {
    color: $text-color;
    font-size: 25px;
    font-family: $font-secondary;
    font-weight: normal;
    margin-bottom: 30px;
  }

  p {
    color: $brand-light;
    font-size: 15px;
    line-height: 1.3;

    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      font-size: 17px;
    }

    &.event-location {
      font-size: 18px;
      color: $brand;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      line-height: 28px;
      word-break: break-word;
      margin-bottom: 15px;
    }
  }

  .distance {
    color: $brand;
    text-align: left;
    margin-top: 20px;

    @include media-breakpoint-up(sm) {
      margin-left: auto;
      text-align: right;
      margin-top: 0;
    }

    .distance-text {
      @extend .d-md-none;
    }
  }

  .meta {
    color: $brand;
    font-size: 15px;
    margin-bottom: 20px;
  }

  .cat {
    border: 1px solid #007bff;
    color: $brand;
    text-align: center;
    padding: 10px 30px;
    font-size: 19px;
    display: inline-block;
  }

  .view {
    color: $brand;
    font-weight: $font-bold;
    font-size: 15px;
    text-align: right;
    text-transform: uppercase;
    letter-spacing: 0.2em;

    position: relative;
    z-index: 1;
    display: block;
    margin-top: auto;

    transition: transform 0.3s ease-in-out;
  }
}

/* POST PAGE
   ========================================================================== */
.page-container {
  background: $white-alt;
  padding: 20px 0 15px;

  @include media-breakpoint-up(lg) {
    padding: 75px 0 100px;
  }

  .back {
    display: flex;

    @include media-breakpoint-up(lg) {
      margin-left: -16px;
    }

    span {
      font-family: $font-secondary;
      font-size: 20px;
      font-style: italic;
      line-height: 1.14;
      color: #2c3946;
      margin-left: 20px;

      @include media-breakpoint-up(lg) {
        font-size: 35px;
      }
    }
  }
}

.post-featured {
  display: flex;
  flex-direction: column;

  &:not(.featured-sticky) {
    background: $white;
  }

  &.featured-sticky {
    position: relative;
    z-index: 1500;
    max-height: 450px;
    max-width: 440px;

    @include media-breakpoint-down(sm) {
      display: none;
    }

    .featured-img {
      @include media-breakpoint-up(lg) {
        height: 450px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    align-items: center;
    flex-direction: row;
  }

  .featured-img {
    width: 100%;
    height: 300px;

    @include media-breakpoint-up(lg) {
      height: 550px;
      width: 50%;
    }

    .img {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
    }
  }

  .featured-content {
    width: 100%;
    padding: 30px 25px;

    @include media-breakpoint-up(xl) {
      width: 50%;
      padding: 44px 80px 66px 70px;
    }

    .cat-link {
      margin-bottom: 25px;

      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }
    }

    .title {
      font-size: 35px;
      line-height: 40px;
      font-family: $font-secondary;
      margin-bottom: 25px;
      color: $text-color;
    }

    .title-alt,
    .title-alt a {
      color: black;
    }

    .date {
      margin-bottom: 15px;
      color: $brand;
    }

    p {
      line-height: 1.5;
      margin-bottom: 25px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 35px;
      }
    }

    .sold {
      font-family: $font-base;
      font-size: 32px;
      color: $text-color;
    }

    .event-info {
      text-align: center;

      @include media-breakpoint-up(lg) {
        text-align: left;
        display: flex;
        align-items: center;
      }

      .btn {
        @include media-breakpoint-up(lg) {
          margin-left: auto;
        }
      }
    }
  }

  &.webinar {
    margin-bottom: 25px;
    &:last-of-type {
      margin-bottom: 0px;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 50px;
      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    .featured-img {
      @include media-breakpoint-up(lg) {
        width: 40%;
        height: 350px;
        order: 2;
      }
    }

    .featured-content {
      text-align: center;
      @include media-breakpoint-up(md) {
        text-align: left;
      }
      @include media-breakpoint-up(lg) {
        width: 60%;
        order: 1;
        padding: 50px 80px 50px 70px;
      }
    }

    .content {
      @extend .line-height-md;
    }
  }
}

.post-content {
  line-height: 1.5;
  margin-bottom: 25px;

  @include media-breakpoint-up(lg) {
    margin-top: 25px;
  }

  &.event-content {
    background: #fff;
    margin-top: 16px;
    padding: 20px 16px;

    @include media-breakpoint-up(lg) {
      margin-top: 60px;
      padding: 30px 50px;
    }

    @include media-breakpoint-up(xl) {
      margin-top: 90px;
      padding: 50px 100px;
    }
  }

  .featured-content {
    padding: 16px;

    @include media-breakpoint-up(lg) {
      padding: 0;
    }

    .cat-link {
      margin-bottom: 42px;
    }

    .post-title {
      font-size: 28px;
      font-family: $font-secondary;
      font-style: italic;
      line-height: 1.14;
      text-align: left;
      color: #2c3946;
      margin-bottom: 20px;

      @include media-breakpoint-up(lg) {
        font-size: 44px;
        margin-bottom: 42px;
      }
    }
  }

  p {
    line-height: 1.5;
    margin-bottom: 25px;
  }

  &.event-content {
    .content-inner .post-share {
      display: none;

      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }
  }
}

.comp-sidebar {
  border: 1px solid $border-color;

  .comp-question {
    padding: 25px 50px;
    background: $brand;
    color: $white;

    @include media-breakpoint-up(xxl) {
      padding: 45px 70px;
    }

    .question-title {
      font-size: 22px;
      color: $white;
      margin-bottom: 20px;
    }

    input.question {
      background: $brand;
      border: none;
      border-bottom: 1px solid $white;
      padding-bottom: 10px;
      width: 100%;
      color: $white;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $white;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $white;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: $white;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: $white;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .comp-form {
    padding: 50px;

    @include media-breakpoint-up(xxl) {
      padding: 70px;
    }

    p {
      margin-bottom: 0;
    }

    button {
      margin-bottom: 0;
    }
  }

  .form-message {
    line-height: 35px;
    text-align: center;
    margin-bottom: 20px;
  }

  .form-submit {
    text-align: center;
  }
}

.event-sidebar {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    max-width: 400px;
  }

  .event-info {
    display: flex;
    justify-content: center;
    margin-bottom: 28px;
  }

  .event-detail {
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 16px;

    @include media-breakpoint-up(lg) {
      display: flex;
      margin-right: 0;
    }

    p {
      color: $brand;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.38;
      letter-spacing: 1.6px;
      text-align: left;
      margin-bottom: 0;

      @include media-breakpoint-up(lg) {
        font-size: 16px;
      }
    }

    img {
      width: 15px;
      margin: 0 10px 0 0;

      @include media-breakpoint-up(lg) {
        width: 20px;
      }
    }
  }

  address {
    font-size: 15px;
    line-height: 1.47;
    letter-spacing: 3px;
    text-align: left;
    text-transform: uppercase;
    color: $text-color;
  }

  img {
    margin-bottom: 30px;
  }

  .view-map {
    position: relative;
    font-size: 17px;
    color: $text-color;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    margin-bottom: 40px;
    display: inline-block;

    @include media-breakpoint-up(md) {
      margin-bottom: 70px;
    }

    &:before {
      content: '\e90c';
      @include icons;
      color: $brand;
      font-size: 20px;
      display: inline-block;
      margin-right: 16px;
      position: relative;
    }
  }
}

.post-share {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @extend .list-unstyled;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    justify-content: normal;
    margin-bottom: 50px;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 0;
  }

  li {
    &:not(:last-child, .first) {
      margin-right: 40px;

      @include media-breakpoint-up(md) {
        margin-right: 50px;
      }
    }

    &.first {
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 1.6px;
      color: #2c3946;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        text-align: left;
        width: auto;
        margin-right: 40px;
        margin-bottom: 0;
      }
    }
  }

  .icon {
    font-size: 16px;
    color: $brand;
  }
}

.yarpp-related {
  margin: 0 !important;

  h3 {
    font-weight: inherit;
  }
}

// Ajax Load More
.ajax-load-more-wrap {
  width: 100%;

  .alm-listing.row {
    margin-left: 0;
    margin-right: 0;
  }
}

.alm-btn-wrap {
  text-align: center;

  .alm-load-more-btn {
    min-width: 280px;

    &.done {
      display: none;
    }
  }
}

/* POST SINGLE
========================================================================== */

.entry-title {
  margin-bottom: 30px;
  max-width: 800px;

  @include media-breakpoint-up(xl) {
    margin-bottom: 40px;
  }

  .cat-link {
    margin-bottom: 30px;

    @include media-breakpoint-up(xl) {
      margin-bottom: 40px;
    }
  }

  h1 {
    font-size: 34px;
    line-height: 1.3;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    word-break: break-word;

    @include media-breakpoint-up(xl) {
      font-size: 40px;
    }
  }
}

.entry-meta {
  margin-bottom: 10px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 30px;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 50px;
  }

  .meta {
    color: $brand;
    font-size: 17px;
    display: inline-block;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  .post-share {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
      margin-left: auto;
    }
  }
}

.entry-content {
  color: #484848;
  margin-bottom: 50px;

  table {
    width: 100%;
  }

  p {
    line-height: 1.35;
    margin-bottom: 25px;

    & + blockquote {
      margin-top: 25px;
    }
  }

  ul,
  ol {
    margin-bottom: 20px;

    li {
      font-size: 15px;
      line-height: 1.6;

      @include media-breakpoint-up(xxl) {
        font-size: 17px;
      }
    }
  }

  h4 {
    line-height: 1.5;
    margin-bottom: 25px;
  }

  h5 {
    margin-bottom: 15px;
  }

  .embed-container {
    margin-top: 30px;

    @include media-breakpoint-up(xl) {
      margin-top: 30px;
    }
  }

  .post-share {
    display: flex;
    margin-top: 60px;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  table {
    @extend .table;
    @extend .table-striped;
  }

  blockquote {
    margin-bottom: 20px;
    border-left: 3px solid #015db8;
    padding: 30px 50px 20px 30px;

    p {
      font-size: 17px;
    }
  }
}

.section-title-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    margin-bottom: 30px;
    justify-content: flex-start;
  }

  .section-title {
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 3.6px;
    color: #2e2e2e;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      margin-right: 25px;
    }
  }

  .line {
    display: none;

    @include media-breakpoint-up(md) {
      background: #838383;
      display: flex;
      height: 2px;
      flex-grow: 1;
    }
  }
}
