/* ==========================================================================

   MIXINS

========================================================================== */

/* LOOP THROUGH HEADRS
========================================================================== */
@function headings($from:1, $to:6) {
  @if $from == $to {
    @return 'h#{$from}';
  } @else {
    @return 'h#{$from},' + headings($from+1, $to);
  }
}

/* ICONS
========================================================================== */

@mixin icons() {

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

@mixin background-cover($pos:center) {
  background-size:cover;
  background-position:$pos;
  background-repeat:no-repeat;
}

@mixin background-contain() {
  background-size:contain;
  background-position:center;
  background-repeat:no-repeat;
}

@mixin background-size($size:50%) {
  background-size:$size;
  background-position:center;
  background-repeat:no-repeat;
}

@mixin background-inner($size:cover, $top:0, $right:0, $bottom:0, $left:0) {
  position: absolute;
  top:$top;
  right:$right;
  bottom:$bottom;
  left:$left;
  background-size:$size;
  background-position:center;
  background-repeat:no-repeat;
  opacity:0.5;
  pointer-events:none;
}

@mixin webkit-line-clamp($clamp: 5) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $clamp;
  -webkit-box-orient: vertical;
}


@mixin single-line-clamp() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin animation-enhance() {
  -webkit-backface-visibility: hidden!important; /* Chrome, Safari, Opera */
  backface-visibility: hidden!important;
  -webkit-perspective: 100!important;
  perspective: 100!important;
}
