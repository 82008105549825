.basic-banner {
    display: flex;
    align-items: center;
    height: 65vh;
    position: relative;

    .banner-bg {
        //@include background-cover();
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        opacity: .3;
        @include media-breakpoint-up(xl) {
            opacity: 1;
        }
    }

    @include media-breakpoint-up(sm) {
        height: 75vh;
    }

    @include media-breakpoint-up(xl) {
        height: 740px;
    }

    .basic-banner-textarea {
        position: relative;
        z-index: 10;
        &__hdr {
            color: $black;
            text-transform: uppercase;
            max-width: 475px;
            margin-bottom: 0;
            letter-spacing: 2px;
            font-size: 40px;

            @include media-breakpoint-up(sm) {
                font-size: 55px;
            }

            @include media-breakpoint-up(xl) {
                font-size: 62px;
            }
        }

        &__para {
            max-width: 600px;
            padding-top: 20px;
        }

        &__hdr,
        &__para {
            &.white {
                color: white;
            }

            &.black {
                color: black;
            }
        }
    }
}
