.inner-background-image {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	opacity:0.5;
	//@include background-cover();
}

.image-crossover-textarea {
	padding-top: 35px;

	.inner-section {
		.inner-section-textarea {

			&__hdr {
				color:$grey-darker;
				margin-bottom: 0;
				line-height: 1.4;
    			font-size: 25px;
    			letter-spacing:3px;
    			padding-bottom:20px;

				@include media-breakpoint-up(sm) {
					padding-bottom:40px;
					letter-spacing:5px;
					font-size:32px;
				}
			}

			&__para {
				padding-bottom: 40px;

				@include media-breakpoint-up(sm) {
					padding-bottom: 50px;
				}

				@include media-breakpoint-up(xxl) {
					padding-bottom: 110px;
				}

				p {
					//color:$grey-alt;
					font-size:18px;
					line-height: 1.5;


					@include media-breakpoint-up(lg) {
						font-size:22px;
					}
				}
			}
		}

		.inner-section-image {
			width:100%;
			background-color:black;
			position: relative;
			height: 375px;

			@include media-breakpoint-up(md) {
				height:725px;
			}

			.testimonial-block {
				position: absolute;
				bottom:0;
				right:0;
				background-color:$brand;
				width: 280px;
				height:auto;
    			display: flex;
    			align-items: center;
    			justify-content: center;
    			padding-left: 30px;
    			padding-right: 30px;
    			padding-top: 30px;
    			padding-bottom: 30px;

    			@include media-breakpoint-up(md) {
					padding-left:45px;
					padding-right:45px;
					padding-top: 45px;
					padding-bottom: 45px;
    			}

    			.inner {

    			}

    			&__hdr {
    				color:$light;
    				font-size:65px;
    				letter-spacing: 7px;
    			}

				&__para {
					color:$light;
					margin-bottom: 0;
				    line-height: 1.7;
				    font-size: 13px;

				    @include media-breakpoint-up(md) {
				    	font-size: 14px;
				    	line-height: 2;
				    }
				}
			}
		}

		&.light-grey {
			background-color:$light-grey;

			.inner-section-textarea {
				background-color:$light-grey;
			}
		}

		&.dark-grey {
			background-color:$grey-darker;

			.inner-section-textarea {
				background-color:$grey-darker;

				&__hdr,
				&__para {
					color:$light;

					p {
						color:$light;
					}
				}
			}
		}

		&.right {
    		position: relative;
    		z-index: 1;

			.inner-section-textarea {
				padding-top: 50px;
    			margin-left: auto;
    			margin-right: auto;
    			max-width: 80%;

    			@include media-breakpoint-up(xxl) {
					max-width: 650px;
    			}

    			&__para {
    				line-height: 1.7!important;
    			}
			}
			.inner-section-image {
				width: 100%;
			}
		}

		&.left {
			padding-bottom: 50px;

			@include media-breakpoint-up(lg) {
				padding-bottom: 100px;
			}

			.inner-section-textarea {
				padding-top: 60px;
    			max-width: 700px;
    			max-width: 80%;
    			margin-left: auto;
    			margin-right: auto;

    			@include media-breakpoint-up(md) {
    				margin-top: -70px;
    				margin-left: -30px;
    				margin-right: 0;
    				padding-top: 140px;
					padding-left: 35px;
					padding-right: 35px;
					max-width: none;
    			}

    			@include media-breakpoint-up(lg) {
	    			padding-left: 70px;
	    			padding-right: 70px;
    			}

    			@include media-breakpoint-up(xxl) {
    				padding-left: 110px;
    			}

    			&__hdr {
    				position: relative;
    				padding-top: 40px;

    				&:before {
    					content:'';
						position: absolute;
						top:0;
						height:2px;
						width:60px;
						background-color:$brand;
    				}
    			}

    			&__para {
    				line-height: 2.2;

    				p,
    				li {
    					font-size: 14px;
    				}

    				p {
    					margin-bottom: 25px;
    					line-height: 2.2;
    				}

    				ul {
    					padding-left: 15px;

    					li {
    						padding-bottom: 9px;
    					}
    				}
    			}
			}

			.inner-section-image {
				width: 100%;
				float:right;
				margin-top: 0;

				@include media-breakpoint-up(md) {
					margin-top: -250px;
				}
			}
		}
	}

	.inner-background-image {
		opacity:1;
	}

	+ .container-image-text {
		.image-text {
			&:first-of-type {
				padding-top: 0;
			}
		}
	}

	&.triple {
		.inner-section {
			position: relative;

			.vertical-center {
				display:flex;
				align-items: center;
			}

			.inner-section-textarea {
    			padding-top: 50px;
    			padding-bottom: 50px;

				@include media-breakpoint-up(md) {
					padding-top: 0;
					padding-bottom: 0;
				}

				&__hdr {
					padding-top: 0!important;
					padding-bottom: 20px!important;
					position: relative;

    				&:before {
    					content:'';
    					display:none!important;
    				}

    				&.divide {
						&:before {
							display:block!important;
							position: absolute;
							top:-30px;
							height:2px;
							width:60px;
							background-color:$brand;
						}
    				}
				}

				&__subhdr {
					margin-bottom:0;
    				color: white;
    				text-transform: uppercase;
    				letter-spacing: 3px;
    				font-size: 20px;
    				padding-top: 10px;
    				padding-bottom: 10px;
    				max-width: 75%;
    				line-height: 1.5;
				}

				&__para {
					padding-top: 20px!important;
					padding-bottom: 0;
					font-size: 17px;
				}
			}

			&.right {
				.inner-section-textarea {
					//color:$grey-alt;

					&__para {
						font-size: 20px;
						line-height: 1.8!important;

						@include media-breakpoint-up(xl) {
							line-height: 2.2!important;
						}
					}
				}

				&.top {
					.inner-section-textarea {
					}
				}
			}

			&.left {
				padding-bottom:0;

				.inner-section-textarea {

					&__hdr {
					}

					&__para {
						font-size: 17px;
						line-height: 1.8!important;

						@include media-breakpoint-up(xl) {
							line-height: 2.2!important;
						}
					}
				}

				.inner-section-image,
				.inner-section-textarea {
					margin-top: 0;
				}
			}

			@include media-breakpoint-up(md) {
				.base {
					position: absolute;
					width:100%;
					height:50px;
					bottom:0;

					&.white {
						background-color:$white;
					}

					&.light-grey {
						background-color:$light-grey;
					}

					&.dark-grey {
						background-color:$grey-darker;
					}
				}
			}

			.to-front {
				position: relative;
				z-index:1;
			}
		}

		&.reverse {
			.inner-section {
				&.right {
					.inner-section-textarea {
						&__para {
							font-size: 17px;
						}
					}
				}

				&.left {
					.inner-section-textarea {
						&__para {
							font-size: 20px;
						}
					}
				}
			}
		}
	}
}
